import React from "react"
import { graphql } from "gatsby"
import { getUserLangKey } from "ptz-i18n"
import { withPrefix } from "gatsby-link"
import { Helmet } from "react-helmet"

const RedirectIndex = args => {
  // Skip build, Browsers only
  if (typeof window !== "undefined") {
    const { langs, defaultLangKey } = args.data.site.siteMetadata.languages
    const langKey = getUserLangKey(langs, defaultLangKey)
    const homeUrl = withPrefix(`/${langKey}/`)

    // devnano.me #1 This code was taken from https://github.com/angeloocana/angeloocana/blob/master/src/pages/index.js#L22
    // it seems that window.history.___replace is simply window.___replace now
    // see https://github.com/gatsbyjs/gatsby/blob/561d33e2e491d3971cb2a404eec9705a5a493602/packages/gatsby/cache-dir/navigation.js#L155

    // I don`t think this is the best solution
    // I would like to use Gatsby Redirects like:
    // https://github.com/gatsbyjs/gatsby/tree/master/examples/using-redirects
    // But Gatsby Redirects are static, they need to be specified at build time,
    // This redirect is dynamic, It needs to know the user browser language.
    // Any ideias? Join the issue: https://github.com/angeloocana/gatsby-starter-default-i18n/issues/4
    window.___replace(homeUrl)
  }
  
  return (
    <Helmet>
      <title>{args.data.site.siteMetadata.title}</title>
      <script
        data-ad-client="ca-pub-6948144106183017"
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      ></script>
    </Helmet>
  );
}

export default RedirectIndex

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
